import dispatchToast from 'components/modules/Toast';
import { getJupiterTransactionHistories } from 'features/membership/api';
import { JUPITER_FEATURE_CODES } from 'features/membership/libs/const/plugins/jupiterFeatureCodes';
import { membershipPluginFeatureState } from 'features/membership/libs/recoil/selector';
import { GetTransactionHistoriesResponse } from 'features/membership/types';
import { API_RESPONSE_STATUS } from 'libs/constants';
import _ from 'lodash';
import { useAsyncFn } from 'react-use';
import { useRecoilValue } from 'recoil';

type UseTransactionHistoriesReturns = {
  transactionHistoriesResponseData:
    | GetTransactionHistoriesResponse['data']
    | undefined;
  isMutating: boolean;
  executeGetTransactionHistories: (
    limit?: number,
    offset?: number
  ) => Promise<GetTransactionHistoriesResponse['data'] | undefined>;
};

/** 取引履歴取得API（VD）を実行するhook */
const useTransactionHistories = (): UseTransactionHistoriesReturns => {
  const isTransactionHistoriesActive = useRecoilValue(
    membershipPluginFeatureState
  ).jupiter?.includes(
    _.camelCase(JUPITER_FEATURE_CODES.GET_TRANSACTION_HISTORIES)
  );

  const [
    { value: transactionHistoriesResponseData, loading: isMutating },
    executeGetTransactionHistories
  ] = useAsyncFn<
    (
      limit?: number,
      offset?: number
    ) => Promise<GetTransactionHistoriesResponse['data'] | undefined>
  >(
    async (limit, offset) => {
      if (!isTransactionHistoriesActive) return;

      const res = await getJupiterTransactionHistories(limit, offset);
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        return res.data;
      }

      dispatchToast({
        id: 'membership-balance',
        toastText: res.errors.join('\n')
      });
    },
    [isTransactionHistoriesActive]
  );

  return {
    transactionHistoriesResponseData,
    isMutating,
    executeGetTransactionHistories
  };
};

export default useTransactionHistories;

import {
  Heading,
  Skeleton,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import GingerBalanceDetailAccordion from 'features/membership/components/ginger/GingerBalanceDetailAccordion';
import GingerTransactionHistoryListItem from 'features/membership/components/ginger/GingerTransactionHistoryListItem';
import usePrepaidBalance from 'features/membership/hooks/ginger/usePrepaidBalance';
import useTransactionHistories from 'features/membership/hooks/ginger/useTransactionHistories';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { JUPITER_FEATURE_CODES } from 'features/membership/libs/const/plugins/jupiterFeatureCodes';
import {
  GingerPagePageTemplateOptionKey,
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import {
  GetTransactionHistoriesResponse,
  PageTemplateOption
} from 'features/membership/types';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import _ from 'lodash';
import NotFound from 'pages/error/NotFound';
import { FC, useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { useRecoilValue } from 'recoil';

/**
 * マネー履歴
 */
const GingerTransactionHistories: FC = () => {
  const templateOptions = useRecoilValue<
    Record<GingerPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.GINGER));

  const jupiterFeatureCodes = useRecoilValue(
    membershipPluginFeatureState
  ).jupiter;
  const isGetTransactionHistoriesFeatureActive = Boolean(
    jupiterFeatureCodes?.includes(
      _.camelCase(JUPITER_FEATURE_CODES.GET_TRANSACTION_HISTORIES)
    )
  );

  const {
    prepaidBalanceResponseData,
    isMutating: isPrepaidBalanceMutating,
    executeGetPrepaidBalance
  } = usePrepaidBalance();
  const {
    transactionHistoriesResponseData,
    executeGetTransactionHistories,
    isMutating: isTransactionHistoriesMutating
  } = useTransactionHistories();

  // NOTE:無限スクロールの間、totalCountが変わる場合、record が重複表示になったしまう
  const isTransactionRecordRepeat = useCallback<
    (
      newRecords: GetTransactionHistoriesResponse['data']['records'],
      currentRecords: GetTransactionHistoriesResponse['data']['records']
    ) => boolean
  >((newRecords, currentRecords) => {
    return currentRecords.some((currentRecord) =>
      newRecords.some((newRecord) => currentRecord.seq_no === newRecord.seq_no)
    );
  }, []);

  const { boxRef, displayData: transactionHistories } = useInfiniteScroll<
    GetTransactionHistoriesResponse['data']['records']
  >(
    transactionHistoriesResponseData?.records ?? [],
    transactionHistoriesResponseData?.total_count ?? 0,
    executeGetTransactionHistories,
    50,
    isTransactionRecordRepeat,
    isTransactionHistoriesMutating
  );

  useEffectOnce(() => {
    executeGetPrepaidBalance();
    executeGetTransactionHistories();
  });

  // 取引履歴取得プラグインがOFFになっている場合は、NOTFOUND表示する
  if (!isGetTransactionHistoriesFeatureActive) return <NotFound />;

  return (
    <MainLayout header footer bg="dark.50">
      <Stack spacing="2rem">
        <Stack px="1rem" pt="3rem" spacing="1rem">
          <Heading
            fontSize="xl"
            fontWeight="700"
            wordBreak="break-all"
            whiteSpace="pre-wrap"
          >
            {/* NOTE: デフォルト値設定はしない */}
            {templateOptions?.houseMoneyNameContentOption?.content}ご利用履歴
          </Heading>
          <Skeleton isLoaded={!isPrepaidBalanceMutating}>
            {prepaidBalanceResponseData && (
              <GingerBalanceDetailAccordion
                balanceTotal={prepaidBalanceResponseData.balance_total}
                balanceBasic={{
                  value: prepaidBalanceResponseData.balance_basic,
                  expireDate: prepaidBalanceResponseData.expire_date_basic
                }}
                balanceBonus={{
                  value: prepaidBalanceResponseData.balance_bonus,
                  expireDate: prepaidBalanceResponseData.expire_date_bonus
                }}
                balanceCoupon={{
                  value: prepaidBalanceResponseData.balance_coupon,
                  expireDate: prepaidBalanceResponseData.expire_date_coupon
                }}
                // NOTE: デフォルト値設定はしない
                unit={templateOptions?.houseMoneyUnitContentOption?.content}
              />
            )}
          </Skeleton>
        </Stack>
        {!!transactionHistories && transactionHistories.length !== 0 ? (
          <UnorderedList listStyleType="none" ml={0}>
            {transactionHistories.map((transactionHistory, index) => (
              <GingerTransactionHistoryListItem
                key={`transaction-history_${index}`}
                title={`${transactionHistory.shop_name}${transactionHistory.type_label}`}
                date={transactionHistory.date}
                amount={transactionHistory.amount}
                // NOTE: デフォルト値設定はしない
                unit={templateOptions?.houseMoneyUnitContentOption?.content}
              />
            ))}
          </UnorderedList>
        ) : (
          !isTransactionHistoriesMutating && (
            <Text textAlign="center" fontWeight={400} fontSize="md">
              履歴はありません
            </Text>
          )
        )}
        <Skeleton
          height={isTransactionHistoriesMutating ? '20px' : undefined}
          isLoaded={!isTransactionHistoriesMutating}
          ref={boxRef}
        />
      </Stack>
    </MainLayout>
  );
};

export default GingerTransactionHistories;

import dispatchToast from 'components/modules/Toast';
import { postMembershipIssueCardConfirmation } from 'features/membership/api';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { earthFormatDataForIssueApi } from 'features/membership/libs/formatDataForApi';
import {
  CuminIssueCardFormInput,
  CuminUserProfileFormInput
} from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { BaseSyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useMemberForm } from './useMemberForm';

type UseCardIssueFormProps = {
  templateAliasCode: Extract<
    (typeof TEMPLATE_ALIAS_CODE)[keyof typeof TEMPLATE_ALIAS_CODE],
    'cumin' | 'ginger'
  >;
  formFields?: string[];
  requiredFormFields?: string[];
};

type UseCardIssueFormReturn = {
  isMutating: boolean;
  methods: UseFormReturn<CuminIssueCardFormInput | CuminUserProfileFormInput>;
  formSubmitHandler: (event?: BaseSyntheticEvent) => Promise<void>;
};

const useCardIssueForm = ({
  templateAliasCode,
  formFields,
  requiredFormFields
}: UseCardIssueFormProps): UseCardIssueFormReturn => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { methods, setServerErrors } = useMemberForm({
    defaultValues: state?.formData,
    formFields,
    requiredFormFields
  });

  const [{ loading: isMutating }, confirmFormData] = useAsyncFn<
    (formData: Partial<CuminIssueCardFormInput>) => Promise<void>
  >(async (formData) => {
    if (typeof formData === 'undefined') return undefined;

    const res = await postMembershipIssueCardConfirmation(
      earthFormatDataForIssueApi(formData)
    );
    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      navigate(`/membership/${templateAliasCode}/issue/confirm`, {
        state: { formData }
      });
      return;
    }

    if (res.error_fields && Object.keys(res.error_fields).length) {
      setServerErrors(res.error_fields);
      return;
    }

    // 個別な項目じゃない問題でエラーした場合
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors?.join('\n')
    });
  }, []);

  const formSubmitHandler = methods.handleSubmit(
    async (formData) => await confirmFormData(formData)
  );

  return {
    isMutating,
    methods,
    formSubmitHandler
  };
};
export default useCardIssueForm;

import {
  getRequestHeader,
  handleMoveToErrorPage,
  handleResetLocalStorage
} from 'api';
import axios from 'axios';
import { PLUGIN_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  CuminIssueCardResponse,
  CuminLinkCardResponse,
  CuminUserProfileResponse,
  ErrorResponse,
  GetMembershipMemberResponse,
  GetMembershipSettingResponse,
  GetPointBalanceResponse,
  GetPrepaidBalanceResponse,
  GetTransactionHistoriesResponse,
  LinkMemberResponse,
  PatchMembershipCardResponse,
  PatchMembershipIssueCardResponse,
  PostMembershipMemberResponse
} from 'features/membership/types';
import {
  CuminCardLinkRequestParams,
  CuminIssueCardRequestParams,
  GingerCardLinkRequestParams
} from 'features/membership/types/form';
import { MEMBERSHIP_API_ENDPOINT, TENANT_CODE } from 'libs/constants';

export const membershipApi = axios.create({
  baseURL: `${MEMBERSHIP_API_ENDPOINT}/${TENANT_CODE}/membership`
});

// TODO: 今後の対応でも構いませんので、responseとerrorのformattingはinterceptorの方でやって、共通にしましょう。
membershipApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    // NOTE: Move the user to /error page in case of the error response with 500 status.
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
    }

    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
      }
    }
    return Promise.reject(error);
  }
);

/** MBS-API001 会員情報登録API */
export const postMembershipMember = (
  cardNumber: string,
  trusted = false
): Promise<PostMembershipMemberResponse | ErrorResponse> =>
  membershipApi
    .request<PostMembershipMemberResponse>({
      url: '/users/current/member',
      method: 'POST',
      data: { card_number: cardNumber, trusted: trusted },
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** MBS-API002 カード番号更新API */
export const patchMembershipCard = (
  cardNumber: string,
  trusted = false
): Promise<PatchMembershipCardResponse | ErrorResponse> =>
  membershipApi
    .request<PatchMembershipCardResponse>({
      url: '/users/current/card',
      method: 'PATCH',
      data: { new_card_number: cardNumber, trusted: trusted },
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** MBS-API003 会員情報照会API */
export const getMembershipMember = (): Promise<
  GetMembershipMemberResponse | ErrorResponse
> =>
  membershipApi
    .request<GetMembershipMemberResponse>({
      url: '/users/current/member',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** 連携情報取得API */
export const getMembershipSetting = (): Promise<
  GetMembershipSettingResponse | ErrorResponse
> =>
  membershipApi
    .request<GetMembershipSettingResponse>({
      url: '/setting',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** チェックディジット、簡易チェック会員連携API（プラグイン） */
export const postMembershipPluginLink = (
  aliasCode: (typeof PLUGIN_ALIAS_CODE)[keyof typeof PLUGIN_ALIAS_CODE],
  cardNumber: string
): Promise<LinkMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${aliasCode}/users/current/member/link`,
      method: 'POST',
      data: { card_number: cardNumber },
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** チェックディジット、簡易チェックカード番号変更API（プラグイン） */
export const patchMembershipPluginCard = (
  aliasCode: (typeof PLUGIN_ALIAS_CODE)[keyof typeof PLUGIN_ALIAS_CODE],
  newCardNumber: string
): Promise<LinkMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${aliasCode}/users/current/card`,
      method: 'PATCH',
      data: { new_card_number: newCardNumber },
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** [会員連携API（CSD連携）] */
/** ポイント残高表示API（CSD連携） */
export const getPointBalance = (
  aliasCode: (typeof PLUGIN_ALIAS_CODE)[keyof typeof PLUGIN_ALIAS_CODE],
  cardNumber: string
): Promise<
  | GetPointBalanceResponse
  | (ErrorResponse & {
      httpStatus: number;
      error_fields?: {
        // ポイント取得可能時間
        available_hour: string;
        // ポイント取得不可時間
        unavailable_hour: string;
      };
    })
> =>
  membershipApi
    .request({
      url: `/plugins/${aliasCode}/users/current/member/points`,
      method: 'GET',
      data: { card_number: cardNumber },
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => ({
      ...error.response.data,
      httpStatus: error.response.status,
      error_fields: error.response.data?.error_fields
    }));

/** カード番号付番API（CSD連携） */
export const postMembershipIssueCard = (
  data: CuminIssueCardRequestParams
): Promise<CuminIssueCardResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member/issue`,
      method: 'POST',
      data,
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード番号付番入力確認API（CSD連携） */
export const postMembershipIssueCardConfirmation = (
  data: CuminIssueCardRequestParams
): Promise<PostMembershipMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member/issue_confirmation`,
      method: 'POST',
      data,
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** ユーザー情報表示API（CSD連携） */
export const getUserProfile = (): Promise<
  CuminUserProfileResponse | ErrorResponse
> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** ユーザー情報編集API（CSD連携） */
export const patchMembershipProfileEdit = (
  data: CuminIssueCardRequestParams
): Promise<PostMembershipMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member`,
      method: 'PATCH',
      data,
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード番号連携API（CSD連携） */
export const postMembershipLinkCard = (
  data: CuminCardLinkRequestParams
): Promise<CuminLinkCardResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member/link`,
      method: 'POST',
      data,
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード連携入力確認API（CSD連携） */
export const postMembershipLinkCardConfirmation = (
  data: CuminCardLinkRequestParams
): Promise<PostMembershipMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member/link_confirmation`,
      method: 'POST',
      data,
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード再連携API（CSD連携） */
export const patchMembershipLinkCard = (
  data: CuminCardLinkRequestParams
): Promise<CuminLinkCardResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member/link`,
      method: 'PATCH',
      data,
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード再連携確認API（CSD連携） */
export const patchMembershipLinkCardConfirmation = (
  data: CuminCardLinkRequestParams
): Promise<PostMembershipMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member/link_confirmation`,
      method: 'PATCH',
      data,
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード再発行API（CSD連携） */
export const patchMembershipIssueCard = (): Promise<
  PatchMembershipIssueCardResponse | ErrorResponse
> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.EARTH}/users/current/member/issue`,
      method: 'PATCH',
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** [会員連携API（VD）] */
/** マネー残高取得API（VD） */
export const getPrepaidBalance = (): Promise<
  GetPrepaidBalanceResponse | ErrorResponse
> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.JUPITER}/users/current/member/balance`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** 取引履歴取得API（VD） */
export const getJupiterTransactionHistories = (
  limit = 50,
  offset = 0
): Promise<GetTransactionHistoriesResponse | ErrorResponse> => {
  const searchParam = new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString()
  });

  return membershipApi
    .request({
      url: `/plugins/${
        PLUGIN_ALIAS_CODE.JUPITER
      }/users/current/member/transaction_histories?${searchParam.toString()}`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);
};

/** [会員連携API（CSD×VD連携）] */
/** カード番号連携API（CSD×VD連携） */
export const postMarsMembershipLinkCard = (
  data: GingerCardLinkRequestParams
): Promise<CuminLinkCardResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.MARS}/users/current/member/link`,
      method: 'POST',
      data,
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード連携入力確認API（CSD×VD連携） */
export const postMarsMembershipLinkCardConfirmation = (
  data: GingerCardLinkRequestParams
): Promise<PostMembershipMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.MARS}/users/current/member/link_confirmation`,
      method: 'POST',
      data,
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード番号再連携API（CSD×VD連携） */
export const patchMarsMembershipLinkCard = (
  data: GingerCardLinkRequestParams
): Promise<CuminLinkCardResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.MARS}/users/current/member/link`,
      method: 'PATCH',
      data,
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード再連携確認API（CSD×VD連携） */
export const patchMarsMembershipLinkCardConfirmation = (
  data: GingerCardLinkRequestParams
): Promise<PostMembershipMemberResponse | ErrorResponse> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.MARS}/users/current/member/link_confirmation`,
      method: 'PATCH',
      data,
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

/** カード再発行API（CSD×VD連携） */
export const patchMarsMembershipIssueCard = (): Promise<
  PatchMembershipIssueCardResponse | ErrorResponse
> =>
  membershipApi
    .request({
      url: `/plugins/${PLUGIN_ALIAS_CODE.MARS}/users/current/member/issue`,
      method: 'PATCH',
      headers: getRequestHeader({ hasIdToken: true, hasAccessToken: true })
    })
    .then((res) => res.data)
    .catch((error) => error.response.data);

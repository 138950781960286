import { Skeleton, Stack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import CuminRenewalFeatureArea from 'features/membership/components/cumin/CuminRenewalFeatureArea';
import { useMembershipProfileResponse } from 'features/membership/hooks/cumin/useMembershipProfileResponse';
import {
  SERVICE_UNAVAILABLE_BUTTON_TEXT,
  SERVICE_UNAVAILABLE_TITLE
} from 'features/membership/libs/const';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/plugins/earthFeatureCodes';
import { REGISTER_TYPE } from 'features/membership/libs/const/registerType';
import {
  CuminPagePageTemplateOptionKey,
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import _ from 'lodash';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * カード紛失・再発行
 */
const CuminCardRenewal: FC = () => {
  const { membershipProfileResponse, isMutating } =
    useMembershipProfileResponse();
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));

  const reissueTitleContent =
    templateOptions?.renewalReissueTitleContentOption?.content ?? '';
  const reissueExplanationContent =
    templateOptions?.renewalReissueExplanationContentOption?.content ?? '';
  const reissueNoticeContent =
    templateOptions?.renewalReissueNoticeContentOption?.content ?? '';
  // digiclue側に確認してボタンテキストが未設定の場合、デフォルトテキストを表示するようになった
  const reissueButtonContent =
    templateOptions?.renewalReissueButtonContentOption?.content ?? '再発行する';

  const relinkTitleContent =
    templateOptions?.renewalRelinkTitleContentOption?.content ?? '';
  const relinkExplanationContent =
    templateOptions?.renewalRelinkExplanationContentOption?.content ?? '';
  const relinkNoticeContent =
    templateOptions?.renewalRelinkNoticeContentOption?.content ?? '';
  // digiclue側に確認してボタンテキストが未設定の場合、デフォルトテキストを表示するようになった
  const relinkButtonContent =
    templateOptions?.renewalRelinkButtonContentOption?.content ??
    '別のカードを連携する';

  const earthFeatureCodes = useRecoilValue(membershipPluginFeatureState).earth;
  const isReissueFeatureActive = Boolean(
    earthFeatureCodes?.includes(_.camelCase(EARTH_FEATURE_CODES.REISSUE))
  );
  const isRelinkFeatureActive = Boolean(
    earthFeatureCodes?.includes(_.camelCase(EARTH_FEATURE_CODES.RELINK))
  );
  const isGetMemberFeatureActive = Boolean(
    earthFeatureCodes?.includes(_.camelCase(EARTH_FEATURE_CODES.GET_MEMBER))
  );

  /** ユーザーの登録タイプが連携・再連携であればtrueを返却 */
  const isLinkRegistered =
    membershipProfileResponse?.register_type === REGISTER_TYPE.LINKAGE ||
    membershipProfileResponse?.register_type === REGISTER_TYPE.RELINKAGE;

  /** 再付番もしくは再連携機能がONの場合はtrue、両方OFFの場合はfalseを返却 */
  const isFeatureActive = isReissueFeatureActive || isRelinkFeatureActive;

  /** 再付番機能がONかつユーザー情報取得がONかつユーザー登録タイプが連携・再連携の場合はtrue */
  const showReissueArea =
    isReissueFeatureActive && isGetMemberFeatureActive && isLinkRegistered;

  /** 再連携・再発行機能が両方OFF もしくは
        ユーザーの登録タイプが付番もしくは再付番 & 再連携機能がOFFの場合は、trueを返却 */
  const showTopArea =
    !isFeatureActive || (!isLinkRegistered && !isRelinkFeatureActive);

  return (
    <MainLayout header footer bg="dark.50">
      <Skeleton isLoaded={!isMutating}>
        <Stack px="1rem" pt="3rem">
          {/* 再連携・再付番機能が両方とも利用できない場合は、会員証TOPへの導線を表示 */}
          {showTopArea ? (
            <CuminRenewalFeatureArea
              titleContent={SERVICE_UNAVAILABLE_TITLE}
              buttonContent={SERVICE_UNAVAILABLE_BUTTON_TEXT}
              buttonLink={`${FEATURES.membership.path}/${TEMPLATE_ALIAS_CODE.CUMIN}`}
            />
          ) : (
            <>
              {/* デジタルカードの再付番を行う */}
              {showReissueArea && (
                <CuminRenewalFeatureArea
                  titleContent={reissueTitleContent}
                  explanationContent={reissueExplanationContent}
                  noticeContent={reissueNoticeContent}
                  buttonContent={reissueButtonContent}
                  buttonLink="reissue"
                />
              )}
              {/* お店で再発行した物理カードを連携する */}
              {isRelinkFeatureActive && (
                <CuminRenewalFeatureArea
                  titleContent={relinkTitleContent}
                  explanationContent={relinkExplanationContent}
                  noticeContent={relinkNoticeContent}
                  buttonContent={relinkButtonContent}
                  buttonLink="relink"
                  buttonVariant="primary-outline-fullwidth-rounded"
                />
              )}
            </>
          )}
        </Stack>
      </Skeleton>
    </MainLayout>
  );
};

export default CuminCardRenewal;

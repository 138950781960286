import dispatchToast from 'components/modules/Toast';
import { patchMembershipProfileEdit } from 'features/membership/api';
import cuminFormatDataForForm from 'features/membership/libs/cuminFormatDataForForm';
import { earthFormatDataForIssueApi } from 'features/membership/libs/formatDataForApi';
import { CuminUserProfileResponse } from 'features/membership/types';
import {
  CuminIssueCardFormInput,
  CuminUserProfileFormInput
} from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { membershipProfileResponseAtom } from 'libs/recoil/atom';
import { BaseSyntheticEvent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useResetRecoilState } from 'recoil';
import { useMemberForm } from './useMemberForm';

type UseProfileEditFormProps = {
  completeRedirectUrl: string;
  formFields?: string[];
  requiredFormFields?: string[];
  membershipProfileResponse?: CuminUserProfileResponse['data'];
};

type UseProfileEditFormReturn = {
  isMutating: boolean;
  methods: UseFormReturn<CuminIssueCardFormInput | CuminUserProfileFormInput>;
  formSubmitHandler: (event?: BaseSyntheticEvent) => Promise<void>;
};

const useProfileEditForm = ({
  completeRedirectUrl,
  formFields,
  requiredFormFields,
  membershipProfileResponse
}: UseProfileEditFormProps): UseProfileEditFormReturn => {
  const navigate = useNavigate();
  /**
   * 会員情報保存
   */
  const resetMembershipProfileResponse = useResetRecoilState(
    membershipProfileResponseAtom
  );

  const { methods, setServerErrors } = useMemberForm({
    defaultValues: membershipProfileResponse
      ? cuminFormatDataForForm(membershipProfileResponse)
      : undefined,
    // 会員情報編集フォームの場合、生年月日とよく行く店舗は必須項目から除外
    formFields: formFields?.filter(
      (fieldName) => !['birthday', 'entry_store_id'].includes(fieldName)
    ),
    requiredFormFields: requiredFormFields?.filter(
      (fieldName) => !['birthday', 'entry_store_id'].includes(fieldName)
    )
  });

  const [{ loading: isMutating }, confirmFormData] = useAsyncFn<
    (formData: Partial<CuminUserProfileFormInput>) => Promise<void>
  >(async (formData) => {
    if (typeof formData === 'undefined') return undefined;

    const res = await patchMembershipProfileEdit(
      earthFormatDataForIssueApi(formData)
    );

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      resetMembershipProfileResponse();

      dispatchToast({
        id: 'membership-member-api-success',
        toastText: '登録情報を保存しました'
      });

      navigate(completeRedirectUrl);
      return;
    }

    if (res.error_fields && Object.keys(res.error_fields).length) {
      setServerErrors(res.error_fields);
      return;
    }

    // 個別な項目じゃない問題で引っかかった場合
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors?.join('\n')
    });
  }, []);

  const formSubmitHandler = methods.handleSubmit(
    async (formData) => await confirmFormData(formData)
  );

  useEffect(() => {
    if (membershipProfileResponse) {
      methods.reset(cuminFormatDataForForm(membershipProfileResponse));
    }
  }, [membershipProfileResponse, methods]);

  return {
    isMutating,
    methods,
    formSubmitHandler
  };
};

export default useProfileEditForm;

import dispatchToast from 'components/modules/Toast';
import { postMembershipIssueCard } from 'features/membership/api';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { earthFormatDataForIssueApi } from 'features/membership/libs/formatDataForApi';
import { CuminIssueCardFormInput } from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useSetRecoilState } from 'recoil';

type UseCardIssueConfirmProps = {
  memberFormData?: Partial<CuminIssueCardFormInput>;
  templateAliasCode: Extract<
    (typeof TEMPLATE_ALIAS_CODE)[keyof typeof TEMPLATE_ALIAS_CODE],
    'cumin' | 'ginger'
  >;
};

const useCardIssueConfirm = ({
  memberFormData,
  templateAliasCode
}: UseCardIssueConfirmProps) => {
  const navigate = useNavigate();
  const setMembershipMemberResponse = useSetRecoilState(
    membershipMemberResponseAtom
  );
  const [{ loading: isMutating }, onIssueButtonClick] = useAsyncFn(async () => {
    if (typeof memberFormData === 'undefined') return;

    const res = await postMembershipIssueCard(
      earthFormatDataForIssueApi(memberFormData)
    );

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      setMembershipMemberResponse(undefined); // MBS-API003 会員情報照会API のレスポンス のグローバルステートを削除
      navigate(`/membership/${templateAliasCode}/issue/confirm`, {
        replace: true
      }); // state を削除
      navigate(`/membership/${templateAliasCode}/issue/complete`); // 完了ページへ
      return;
    }
    // 個別な項目じゃない問題で引っかかった場合
    if (!(res.error_fields && Object.keys(res.error_fields).length)) {
      dispatchToast({
        id: 'membership-member-api-error',
        toastText: res.errors.join('\n')
      });
      return;
    }

    if (res.error_fields && Object.keys(res.error_fields).length) {
      navigate(`/membership/${templateAliasCode}/issue`, {
        state: { formData: memberFormData, errors: res.error_fields }
      });
      return;
    }

    dispatchToast({
      id: 'membership-member-api-error',
      toastText:
        'エラーが発生しております。暫く経ってからもう一度お試しください'
    });
  }, [memberFormData, navigate]);

  return {
    onIssueButtonClick,
    isMutating
  };
};
export default useCardIssueConfirm;
